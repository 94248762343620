import React from 'react'
import { Button } from 'rebass'
import Icon from 'components/Icon'
import TextInput from 'components/TextInput'
import Table from 'components/Table'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { MdDelete } from 'react-icons/md'

const defaultOptions = ['Size', 'Color', 'Material']

export const initialState = (value = {}) =>
  initializeState({
    product: value.product || {},
    id: value.id || '',
    options: value.options || [],
    price: value.price || 0,
    postedPrice: value.postedPrice || 0,
    sku: value.sku || '',
    barcode: value.barcode || ''
  })

const validation = {
  sku: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 32, message: ['error.maxLength', { val: 25 }] }
  ]
}

export const fields = ({ app, session, state, setState, refs }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)

  const onOptionChange = (key, index) => event => {
    const options = [...state.options]
    options[index][key] = event.target.value
    setState({ ...state, options })
  }

  const onOptionDelete = rowIdx => {
    let options = [...state.options]
    options.splice(rowIdx, 1)

    if (options.length === 0) {
      options = []
    }

    setState({
      ...state,
      options
    })
  }

  return {
    options: (
      <Table
        columns={[
          {
            id: 'key',
            label: 'product.variant.option.key',
            width: 1 / 4,
            render: ({ row, index }) => (
              <TextInput
                value={row.name}
                onChange={onOptionChange('name', index)}
              />
            )
          },
          {
            id: 'value',
            label: 'product.variant.option.val',
            render: ({ row, index }) => (
              <TextInput
                value={row.value}
                onChange={onOptionChange('value', index)}
              />
            )
          },
          {
            id: 'action',
            width: '30px',
            render: ({ row, index }) => (
              <Button
                type="button"
                variant="editor"
                onClick={() => onOptionDelete(index)}
              >
                <Icon mb={3}>
                  <MdDelete />
                </Icon>
              </Button>
            )
          }
        ]}
        rows={state.options}
        rowProps={{ bg: 'grey.0' }}
        cellProps={{ py: 0, sx: { border: 'none' } }}
      />
    ),
    sku: (
      <TextInput
        id="sku"
        label="product.field.sku"
        value={state.sku}
        onChange={onTextChange('sku')}
        errMsg={state.__error__.sku}
      />
    ),
    barcode: (
      <TextInput
        id="barcode"
        label="product.field.barcode"
        value={state.barcode}
        onChange={onTextChange('barcode')}
        errMsg={state.__error__.barcode}
      />
    ),
    price: (
      <TextInput
        id="price"
        type="number"
        min="0"
        label="product.field.price"
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.price}
      />
    ),
    postedPrice: (
      <TextInput
        id="postedPrice"
        type="number"
        min="0"
        label="product.field.postedPrice"
        value={state.postedPrice}
        onChange={onTextChange('postedPrice')}
        errMsg={state.__error__.postedPrice}
      />
    )
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  match
}) => ({
  handleLoad: async () => {
    const { id: productId, variantId } = match.params
    const value = await getDetail({ app, session, productId, variantId })
    setState(initialState(value))
  },
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = state.id
      ? await editProductVariant(state, app, session)
      : await addProductVariant(state, app, session)
    if (ok) {
      history.push(`/product/${state.product.id}/edit`)
    }
  },
  handleDelete: id => async () => {
    const variables = { id }
    const query = `
      mutation ($id: ID!) {
        deleteProductVariant(id: $id)
      }
    `
    const [ok] = await request({ query, variables }, { session, app })
    if (!ok) {
      return
    }
  },
  addOption: () => {
    const options = [...state.options]
    const unusedOptions = defaultOptions.filter(
      item => !options.map(option => option.name).includes(item)
    )
    options.push({ name: unusedOptions[0], value: [] })

    setState({
      ...state,
      options
    })
  }
})

async function getDetail({ app, session, productId, variantId }) {
  const variables = { productId }
  if (variantId) variables.variantId = variantId

  let query = `
    productVariant (id: $variantId) {
      id
      sku
      barcode
      price
      postedPrice
      options {
        name
        value
      }
      status
    }
  `
  query = `
    query ($productId: ID!${variantId ? ', $variantId: ID' : ''}) {
      product (id: $productId) {
        id
        spu
        images {
          src
          alt
          variantId
        }
        options {
          name
          value
        }
        status
      }
      ${variantId ? query : ''}
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  const { product, productVariant } = data
  return { product, ...productVariant }
}

async function addProductVariant(value, app, session) {
  const input = {
    options: value.options,
    sku: value.sku,
    barcode: value.barcode,
    price: parseInt(value.price),
    postedPrice: parseInt(value.postedPrice)
  }

  const variables = { productId: value.product.id, input }
  const query = `
    mutation ($productId: ID!, $input: ProductVariantInput!) {
      addProductVariant(productId: $productId, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function editProductVariant(value, app, session) {
  const input = {
    options: value.options,
    sku: value.sku,
    barcode: value.barcode,
    price: parseInt(value.price),
    postedPrice: parseInt(value.postedPrice)
  }

  const variables = { id: value.id, input }
  const query = `
    mutation ($id: ID!, $input: ProductVariantInput!) {
      editProductVariant(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}
