import React, { useState, useEffect, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'rebass'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import Table from 'components/Table'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import ProductShelfModal from 'views/ProductShelfModal'
import * as model from 'models/productShelfList'

export default ({ match }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [products, setProducts] = useState([])
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({})
  const [data, setData] = useState({})
  const [open, setOpen] = useState(false)

  useEffect(() => {
    model.onLoad({ match, session, app, setProducts, setItems, setPagination })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = item => {
    setData(item)
    setOpen(true)
  }
  const handleOk = item => {
    setItems([...items, item])
    setOpen(false)
    setData({})
  }
  const handleClose = () => {
    setOpen(false)
    setData({})
  }
  const handleDelete = productId => {
    const idx = items.findIndex(item => item.productId === productId)
    const newItems = [...items]
    newItems.splice(idx, 1)
    setItems(newItems)
  }
  const handlePageChange = ({ page, filter }) => {
    model.onLoad({
      match,
      session,
      app,
      setProducts,
      setItems,
      setPagination,
      page,
      filter
    })
  }

  return (
    <Page
      title="productShelf.title.list"
      navId="productShelfList"
      actions={
        <Button
          type="button"
          variant="outlineSecondary"
          onClick={() => handleOpen()}
        >
          <FormattedMessage id="btn.add" />
        </Button>
      }
    >
      <Section>
        <Table
          columns={model.columns({
            app,
            session,
            actions: { handleDelete }
          })}
          rows={items}
          pagination={pagination}
          filters={model.filters}
          onChange={handlePageChange}
        />
      </Section>
      <ProductShelfModal
        open={open}
        value={{ ...data, products }}
        actions={{ handleClose, handleOk }}
      />
    </Page>
  )
}
