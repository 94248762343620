import React, { useState, useRef } from 'react'
import { Flex, Box, Button } from 'rebass'
import LinkButton from 'components/LinkButton'
import Message from 'components/Message'
import Form from 'components/Form'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import DiscountGenerateModal from 'views/DiscountGenerateModal'
import DiscountAddModal from 'views/DiscountAddModal'
import * as model from 'models/discountPage'
import { MdArrowBack } from 'react-icons/md'

export default ({ match }) => {
  const [openGenerate, setOpenGenerate] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const refs = {
    specRef: useRef(null)
  }

  return (
    <Form {...model} match={match} refs={refs}>
      {({ form, action, state }) => (
        <Page
          title="discount.title.add"
          navId="discountList"
          actions={
            <>
              <LinkButton variant="plain" url="/discount">
                <MdArrowBack />
                <Message ml={1} id="btn.back" />
              </LinkButton>
              <Button variant="outlineAccent">
                <Message id="btn.save" />
              </Button>
            </>
          }
        >
          <Box
            mt={2}
            mb={4}
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gridGap: 3
            }}
          >
            <Section title="discount.section.basic">
              {form.name}
              {form.startDate}
              {form.endDate}
              {form.allowCombination}
            </Section>
            <Section title="discount.section.discount">
              {form.discountType}
              {form.discountValue}
              {form.product}
              {form.discountScope}
              {form.minAmount}
              {form.minQuantity}
              {form.maxUserQuantity}
            </Section>
          </Box>
          <Section title="discount.section.code">
            {form.codes}
            <Flex justifyContent="center" alignItems="center" mt={3}>
              <Button
                mr={3}
                type="button"
                variant="outlineSecondary"
                onClick={() => setOpenGenerate(true)}
              >
                <Message id="discount.btn.generateCode" />
              </Button>
              <Button
                type="button"
                variant="outlineSecondary"
                onClick={() => setOpenAdd(true)}
              >
                <Message id="discount.btn.addCode" />
              </Button>
            </Flex>
          </Section>
          <DiscountGenerateModal
            open={openGenerate}
            actions={{
              onOk: action.handleAddCodes,
              onClose: () => setOpenGenerate(false)
            }}
          />
          <DiscountAddModal
            open={openAdd}
            actions={{
              onOk: action.handleAddCodes,
              onClose: () => setOpenAdd(false)
            }}
          />
        </Page>
      )}
    </Form>
  )
}
