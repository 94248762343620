import React from 'react'
import { Button } from 'rebass'
import Icon from 'components/Icon'
import TextInput from 'components/TextInput'
import Table from 'components/Table'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { MdDelete } from 'react-icons/md'

const defaultOptions = ['Size', 'Color', 'Material']

export const initialState = (options, value = {}) =>
  initializeState({
    id: value.id || '',
    options: getOptions(options, value.options),
    price: value.price || 0,
    postedPrice: value.postedPrice || 0,
    sku: value.sku || '',
    barcode: value.barcode || '',
    image: value.image || {}
  })

const validation = {
  sku: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 32, message: ['error.maxLength', { val: 25 }] }
  ]
}

export const fields = ({ state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)

  const onOptionChange = (key, index) => event => {
    const options = [...state.options]
    options[index][key] = event.target.value
    setState({ ...state, options })
  }

  const onOptionDelete = rowIdx => {
    let options = [...state.options]
    options.splice(rowIdx, 1)
    setState({ ...state, options })
  }

  return {
    options: (
      <Table
        columns={[
          {
            id: 'key',
            label: 'product.variant.option.key',
            width: 1 / 4,
            render: ({ row, index }) => (
              <TextInput
                value={row.name}
                onChange={onOptionChange('name', index)}
              />
            )
          },
          {
            id: 'value',
            label: 'product.variant.option.val',
            render: ({ row, index }) => (
              <TextInput
                value={row.value}
                onChange={onOptionChange('value', index)}
              />
            )
          },
          {
            id: 'action',
            width: '30px',
            render: ({ row, index }) => (
              <Button
                mb={3}
                type="button"
                variant="editor"
                onClick={() => onOptionDelete(index)}
              >
                <Icon>
                  <MdDelete />
                </Icon>
              </Button>
            )
          }
        ]}
        rows={state.options}
        rowProps={{ bg: 'grey.0' }}
        cellProps={{ py: 0, sx: { border: 'none' } }}
      />
    ),
    sku: (
      <TextInput
        id="sku"
        label="product.field.sku"
        value={state.sku}
        onChange={onTextChange('sku')}
        errMsg={state.__error__.sku}
      />
    ),
    barcode: (
      <TextInput
        id="barcode"
        label="product.field.barcode"
        value={state.barcode}
        onChange={onTextChange('barcode')}
        errMsg={state.__error__.barcode}
      />
    ),
    price: (
      <TextInput
        id="price"
        type="number"
        min="0"
        label="product.field.price"
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.price}
      />
    ),
    postedPrice: (
      <TextInput
        id="postedPrice"
        type="number"
        min="0"
        label="product.field.postedPrice"
        value={state.postedPrice}
        onChange={onTextChange('postedPrice')}
        errMsg={state.__error__.postedPrice}
      />
    )
  }
}

export const handlers = ({ state, setState, actions }) => ({
  handleConfirm: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    actions.handleSubmit(state)
  },
  addOption: () => {
    const options = [...state.options]
    const unusedOptions = defaultOptions.filter(
      item => !options.map(option => option.name).includes(item)
    )
    options.push({ name: unusedOptions[0], value: [] })

    setState({ ...state, options })
  }
})

function getOptions(opt1, opt2 = []) {
  if (!opt1 || opt1.length === 0) return opt2
  if (!opt2 || opt2.length === 0) {
    return opt1.map(({ name }) => ({ name, value: [] }))
  }

  const extraList = []
  opt1.forEach(({ name }) => {
    const matched = opt2.some(i2 => i2.name === name)
    if (!matched) {
      extraList.push({ name, value: [] })
    }
  })
  return [...opt2, ...extraList]
}
