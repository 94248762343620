export default {
  'error.required': '此欄位為必填欄',
  'error.min': '最小值需為{val}',
  'error.minLength': '最少字符需為{val}',
  'error.maxLength': '最多字符為{val}',
  'error.token.missing': '請先登錄會員',
  'error.token.expired': '請重新登錄！',
  'error.token.invalid': '帳戶與密碼不符！',
  'error.token.staffNotFound': '帳戶與密碼不符！',
  'error.staff.notFound': '請重新登錄！',
  'error.staff.invalidCredential': '帳戶與密碼不符！',
  'error.staff.duplicate': '員工已存在',
  'error.product.notFound': '查無此商品！',
  'error.product.variantRequired': '商品需設定至少一個屬性',
  'error.inventoryBalance.notFound': '倉庫數量不足',
  'error.productShelf.duplicate': '上架商品已存在',
  'error.productVariant.duplicate': '商品SKU已存在',
  'error.category.duplicate': '商品分類已存在',
  'error.payment.notFound': '查無支付紀錄',
  'error.payment.amountExceedsBilled': '付款金額超出訂單金額',
  'error.imageDropzone.tooLarge': '檔案「{filename}」超出限定大小{maxSize}MB',
  'error.ticket.statusExist': '狀態已存在！',
  'error.ticket.notFound': '查無此訂單',
  'error.ecpay.refundFailed': 'ECPAY退款失敗',
  'success.save': '儲存成功',
  'btn.submit': '提交',
  'btn.confirm': '確認',
  'btn.approve': '核准',
  'btn.reject': '駁回',
  'btn.save': '儲存',
  'btn.back': '返回',
  'btn.cancel': '取消',
  'btn.preview': '預覽',
  'btn.close': '關閉',
  'btn.add': '新增',
  'btn.delete': '刪除',
  'btn.more': '更多',
  'btn.export': '匯出',
  'btn.download': '下載',
  'btn.edit.alt': '編輯替代文字',
  'placeholder.textfield': '請輸入{label}',
  'placeholder.select.create': '新增「{name}」',
  'app.name': 'MR. ADMIN',
  'app.login': '登錄',
  'app.logout': '登出',
  'app.signup': '註冊',
  'app.password': '登錄帳密',
  'app.login.required': '請先登錄會員！',
  'app.message.uploadFile': '請將需匯入的文件拖入此框中...',
  'demo.message': '測試系統將於{time}後重置系統資料',
  'filter.placeholder': '請選擇...',
  'filter.op.eq': '等於',
  'filter.op.ge': '大於',
  'filter.op.le': '小於',
  'home.title': 'Welcome - Mr. Admin',
  'login.title': '登錄管理平台',
  'login.field.username': '帳號',
  'login.field.password': '密碼',
  'login.btn.login': '登錄',
  'login.success': '成功登錄',
  'logout.success': '成功登出',
  'account.password.title': '登錄帳密',
  'account.field.password': '密碼',
  'account.field.newPassword': '新密碼',
  'account.field.newPasswordConfirm': '確認新密碼',
  'account.error.passNotMatch': '密碼與確認密碼不相同',
  'account.success.editPassword': '成功更改密碼，請重新登錄',
  'setting.title': '系統設置',
  'dashboard.title': 'Dashboard',
  'dashboard.section.daily': '近3日訂單總數',
  'dashboard.section.weekly': '近1週訂單總數',
  'dashboard.section.monthly': '近1月訂單總數',
  'dashboard.section.order': '近1月每日訂單數量',
  'dashboard.section.ship': '待出貨商品',
  'dashboard.section.refund': '待退貨商品',
  'dashboard.ticketType.SELL': '消費',
  'dashboard.ticketType.REFUND': '退款',
  'category.title': '商品類別',
  'category.title.list': '商品類別',
  'category.title.add': '新增商品類別',
  'category.title.edit': '更改商品類別',
  'category.title.delete': '刪除商品類別',
  'category.section.path': '商品類別路徑',
  'category.message.delete': '確認刪除商品類別?',
  'category.field.name': '類別名稱',
  'staff.title.list': '員工列表',
  'staff.title.add': '新增員工',
  'staff.title.edit': '更改員工',
  'staff.title.delete': '刪除員工',
  'staff.message.delete': '確認刪除員工?',
  'staff.field.type': '部門',
  'staff.field.username': '員工帳號',
  'staff.field.password': '員工密碼',
  'staff.error.passNotMatch': '密碼與確認密碼不相同',
  'staff.type.OWNER': 'Owner',
  'staff.type.ADMIN': '管理員',
  'staff.type.HR': '行政',
  'staff.type.ACCOUNTANT': '會計',
  'staff.type.SALES': '業務',
  'permission.title.list': '權限列表',
  'permission.title.add': '新增權限',
  'permission.title.edit': '更改權限',
  'permission.title.delete': '刪除權限',
  'permission.message.delete': '確認刪除權限?',
  'permission.section.role': '角色',
  'permission.section.location': '管理門店',
  'permission.section.permission': '管理權限',
  'permission.field.name': '角色名稱',
  'permission.field.module': '模塊',
  'permission.field.action': '功能',
  'permission.field.status': '狀態',
  'warehouse.title.list': '倉庫設置',
  'warehouse.title.add': '新增倉庫',
  'warehouse.title.edit': '更改倉庫',
  'warehouse.title.delete': '刪除倉庫',
  'warehouse.message.delete': '確認刪除倉庫?',
  'warehouse.field.name': '倉庫名稱',
  'location.title.list': '群組設置',
  'location.title.add': '新增群組',
  'location.title.edit': '更改群組',
  'location.title.delete': '刪除群組',
  'location.section.path': '群組路徑',
  'location.message.delete': '確認刪除群組?',
  'location.field.name': '群組名稱',
  'location.type.VIRTUAL': '群組',
  'location.type.WAREHOUSE': '倉庫',
  'location.type.STORE': '商店',
  'location.type.STORE_WEB': '電商',
  'store.title.list': '商店設置',
  'store.title.add': '新增商店',
  'store.title.edit': '更改商店',
  'store.title.delete': '刪除商店',
  'store.message.delete': '確認刪除商店?',
  'store.field.name': '商店名稱',
  'inventory.title': '倉庫管理',
  'inventory.title.list': '倉庫目錄',
  'inventory.field.spu': 'SPU',
  'inventory.field.sku': 'SKU',
  'inventory.field.options': '屬性',
  'inventory.field.quantity': '可用數量',
  'backorder.title': '預購管理',
  'backorder.title.list': '預購目錄',
  'backorder.field.spu': 'SPU',
  'backorder.field.sku': 'SKU',
  'backorder.field.options': '屬性',
  'backorder.field.quantity': '預購數量',
  'backorder.btn.addPurchase': '新增採購',
  'order.title': '訂單管理',
  'order.title.list': '訂單目錄',
  'order.title.add': '新增訂單狀態',
  'order.field.id': '訂單編號',
  'order.field.fromLocation': '出貨處',
  'order.field.toLocation': '客戶',
  'order.field.transDate': '交易日',
  'order.field.paymentId': '交易號',
  'order.field.transNo': '三方交易號',
  'order.field.status': '狀態',
  'order.status.ALL': '所有訂單',
  'order.status.INACTIVE': '已取消',
  'order.status.PENDING': '待處理',
  'order.status.ACTIVE': '已完成',
  'order.status.SHIP_PENDING': '待出貨',
  'order.status.SHIPPED': '已發貨',
  'order.status.PAY_PENDING': '待付款',
  'order.status.ARRIVED': '已送達',
  'order.status.REFUND_PENDING': '待退款',
  'order.status.FULL_REFUND': '已退款',
  'order.status.PARTIAL_REFUND': '部份退款',
  'order.title.cancelRefund': '駁回退款',
  'order.message.cancelRefund': '確認駁回退款？',
  'order.title.confirmRefund': '核准退款',
  'order.message.confirmRefund': '確認核准退款？',
  'order.btn.importTrackingNo': '批量出貨',
  'trackingNo.title.list': '批量出貨',
  'trackingNo.section.error': '錯誤信息',
  'trackingNo.field.ticketId': '訂單號',
  'trackingNo.field.trackingNo': '備註',
  'trackingNo.field.errMsg': '錯誤信息',
  'trackingNo.btn.add': '添加出貨訂單',
  'trackingNo.message.success': '成功出貨',
  'trackingNo.message.template': '批量出貨EXCEL匯入範本：',
  'payment.title.add': '新增付款紀錄',
  'payment.field.transDate': '日期',
  'payment.field.transType': '交易類型',
  'payment.field.channelType': '支付類型',
  'payment.field.channelProvider': '付款商',
  'payment.field.transAmount': '金額 (NT)',
  'payment.field.status': '狀態',
  'payment.status.INACTIVE': '失敗',
  'payment.status.ACTIVE': '成功',
  'payment.status.PENDING': '待支付',
  'payment.transType.payment': '支付',
  'payment.transType.refund': '退款',
  'payment.channelType.cash': '現金',
  'payment.channelType.creditCard': '信用卡',
  'payment.channelProvider.self': '自有通路',
  'payment.channelProvider.ecpay': 'ECPay',
  'payment.channelProvider.linepay': 'LINE Pay',
  'payment.method.cash': '到貨付款',
  'shipping.field.timestamp': '時間',
  'shipping.field.status': '狀態',
  'shipping.field.memo': '備註',
  'refund.title.add': '新增退貨紀錄',
  'refund.field.id': '退貨單號',
  'refund.field.product': '商品名稱',
  'refund.field.quantity': '退貨數量',
  'refund.field.transDate': '退貨日期',
  'refund.field.status': '退貨狀態',
  'refund.status.ACTIVE': '已退款',
  'refund.status.PENDING': '處理中',
  'refund.status.INACTIVE': '已駁回',
  'orderDetail.title': '訂單詳情',
  'orderDetail.title.refund': '退款',
  'orderDetail.title.cancel': '取消訂單',
  'orderDetail.message.refund': '確認退款？',
  'orderDetail.message.cancel': '確認取消訂單？',
  'orderDetail.btn.cancelOrder': '取消訂單',
  'orderDetail.section.order': '訂單信息',
  'orderDetail.section.orderItems': '商品列表',
  'orderDetail.section.shipping': '收貨地址',
  'orderDetail.section.shippingStatus': '出貨紀錄',
  'orderDetail.section.payment': '付款紀錄',
  'orderDetail.section.refund': '退貨紀錄',
  'orderDetail.btn.addShippingStatus': '新增出貨紀錄',
  'orderDetail.btn.addRefund': '新增退貨紀錄',
  'orderDetail.btn.addPayment': '新增付款紀錄',
  'orderDetail.field.ticketNo': '訂單編號',
  'orderDetail.field.transDate': '訂單日期',
  'orderDetail.field.transNo': '三方交易號',
  'orderDetail.field.shipping.name': '收件人姓名',
  'orderDetail.field.shipping.phone': '收件人電話',
  'orderDetail.field.shipping.address': '收件人地址',
  'orderDetail.field.shipping.memo': '收件備註',
  'orderDetail.field.orderPrice': '訂單金額',
  'orderDetail.field.paymentMethod': '支付方式',
  'orderDetail.field.discountCode': '折扣碼',
  'orderDetail.field.discountPrice': '折扣金額',
  'orderDetail.field.totalPrice': '總金額',
  'orderDetail.field.shippingFee': '運費',
  'orderDetail.field.status': '訂單狀態',
  'orderDetail.table.product': '商品',
  'orderDetail.table.unitPrice': '單價 (NT)',
  'orderDetail.table.quantity': '數量',
  'orderDetail.table.price': '總計 (NT)',
  'orderDetail.table.shippingName': '收件人姓名',
  'orderDetail.table.shippingPhone': '收件人電話',
  'orderDetail.table.shippingAddress': '收件人地址',
  'order.field.date': '日期',
  'order.field.time': '時間',
  'order.field.memo': '備註',
  'receipt.type.RECEIPT_PERSONAL': '電子發票',
  'receipt.type.RECEIPT_BUSINESS': '三聯式發票',
  'receipt.type.RECEIPT_DONATE': '捐贈發票',
  'receipt.field.receiptType': '發票類型',
  'receipt.field.receiptNo': '統一編號',
  'receipt.field.receiptTitle': '統編抬頭',
  'receipt.field.donationNo': '社福團體愛心碼',
  'customer.title': '客戶管理',
  'customer.title.list': '客戶目錄',
  'customer.title.add': '新增客戶',
  'customer.title.view': '客戶詳情',
  'customer.section.basic': '基礎信息',
  'customer.section.address': '收貨地址',
  'customer.section.ticket': '訂單列表',
  'customer.field.name': '姓名',
  'customer.field.phone': '電話',
  'customer.field.address': '地址',
  'customer.field.ticketId': '訂單編號',
  'customer.field.createdAt': '加入日期',
  'customer.field.transDate': '交易日期',
  'customer.field.price': '金額',
  'product.title': '商品管理',
  'product.title.list': '商品目錄',
  'product.title.add': '新增商品',
  'product.title.edit': '更改商品',
  'product.title.delete': '刪除商品',
  'product.message.delete': '確認刪除商品?',
  'product.section.basic': '基礎信息',
  'product.section.channel': '商品通路',
  'product.section.category': '商品分類',
  'product.section.tag': '商品標籤',
  'product.section.shipping': '物流信息',
  'product.section.order': '銷售設定',
  'product.section.image': '商品示圖',
  'product.section.price': '商品售價',
  'product.section.inventory': '商品庫存',
  'product.section.variant': '商品屬性',
  'product.placeholder.option.key': '尺寸/顏色/材料...',
  'product.field.category': '商品分類',
  'product.field.tag': '商品標籤',
  'product.field.shippingFee': '商品運費',
  'product.field.shippingWaiverFee': '免運費門檻金額',
  'product.field.name': '商品名稱',
  'product.field.spu': 'SPU',
  'product.field.price': '售價',
  'product.field.postedPrice': '定價',
  'product.field.spec': '規格',
  'product.field.desc': '說明',
  'product.field.sku': 'SKU',
  'product.field.barcode': '條碼',
  'product.field.quantity': '數量',
  'product.field.option': '選項',
  'product.field.variant': '屬性',
  'product.message.variant': '此產品有多個選項，例如不同的尺寸或顏色',
  'product.image.title.alt': '編輯圖片替代文字',
  'product.image.field.alt': '圖片替代文字',
  'product.variant.title.add': '新增屬性',
  'product.variant.section.product': '商品信息',
  'product.variant.section.option': '商品選項',
  'product.variant.section.image': '商品示圖',
  'product.variant.section.basic': '庫存信息',
  'product.variant.btn.add': '新增屬性',
  'product.variant.option.btn.add': '新增選項',
  'product.variant.option.key': '選項名稱',
  'product.variant.option.val': '選項值',
  'purchase.title.list': '採購目錄',
  'purchase.title.add': '新增採購單',
  'purchase.title.edit': '採購單詳情',
  'purchase.title.delete': '刪除採購單',
  'purchase.title.accept': '接收商品',
  'purchase.message.delete': '確認刪除採購單?',
  'purchase.section.product': '採購商品',
  'purchase.section.toLocation': '目的地',
  'purchase.section.basic': '基礎信息',
  'purchase.section.arrival': '接收紀錄',
  'purchase.field.id': '單號',
  'purchase.field.toLocation': '目的地',
  'purchase.field.transDate': '採購日期',
  'purchase.field.acceptDate': '接收日期',
  'purchase.field.productFilter': '輸入搜尋商品名稱',
  'purchase.field.spu': 'SPU',
  'purchase.field.sku': 'SKU',
  'purchase.field.quantity': '採購數量',
  'purchase.field.allQuantity': '總數 / 已接收 / 已取消',
  'purchase.field.acceptQuantity': '接收數量',
  'purchase.field.cancelQuantity': '取消數量',
  'purchase.field.status': '狀態',
  'purchase.status.ACTIVE': '已完成',
  'purchase.status.PARTIAL': '部份',
  'purchase.status.PENDING': '待處理',
  'purchase.btn.addAccept': '接收商品',
  'purchase.message.arrivedAt': '於{transDate}接收商品',
  'adjust.title.list': '調整庫存',
  'adjust.title.add': '新增調整單',
  'adjust.title.edit': '調整單詳情',
  'adjust.title.delete': '刪除調整單',
  'adjust.message.delete': '確認刪除調整單?',
  'adjust.section.product': '商品',
  'adjust.section.toLocation': '目的地',
  'adjust.section.basic': '基礎信息',
  'adjust.field.id': '單號',
  'adjust.field.toLocation': '目的地',
  'adjust.field.transDate': '日期',
  'adjust.field.productFilter': '輸入搜尋商品名稱',
  'adjust.field.spu': 'SPU',
  'adjust.field.sku': 'SKU',
  'adjust.field.balance': '現有庫存',
  'adjust.field.quantity': '調整後庫存',
  'adjust.field.status': '狀態',
  'move.title.list': '調貨目錄',
  'move.title.add': '新增調貨單',
  'move.title.delete': '刪除調貨單',
  'move.message.delete': '確認刪除調貨單?',
  'move.section.product': '商品',
  'move.section.fromLocation': '發貨地',
  'move.section.toLocation': '目的地',
  'move.field.id': '單號',
  'move.field.fromLocation': '發貨地',
  'move.field.toLocation': '目的地',
  'move.field.transDate': '下單日',
  'move.field.productFilter': '輸入搜尋商品名稱',
  'move.field.sku': 'SKU',
  'move.field.availableQuantity': '可用數量',
  'move.field.quantity': '數量',
  'move.field.status': '狀態',
  'productShelf.title.list': '上架目錄',
  'productShelf.title.add': '新增上架商品',
  'productShelf.title.edit': '修改上架商品',
  'productShelf.title.delete': '刪除上架商品',
  'productShelf.message.delete': '確認刪除上架商品?',
  'productShelf.section.basic': '目的地信息',
  'productShelf.section.items': '商品列表',
  'productShelf.btn.add': '新增商品',
  'productShelf.field.locationName': '目的地',
  'productShelf.field.productId': '商品SPU',
  'productShelf.field.spu': '商品名稱',
  'estore.title': '電商設置',
  'estore.title.edit': '商家信息',
  'estore.section.basic': '基礎信息',
  'estore.section.warehouse': '倉庫設定',
  'estore.section.logo': '商家LOGO',
  'estore.field.name': '商家名稱',
  'estore.field.companyName': '公司全名',
  'estore.field.companyEmail': '公司EMAIL',
  'estore.field.companyPhone': '公司電話',
  'estore.field.companyAddress': '公司地址',
  'estore.field.headerLogoUrl': '版頭 Logo',
  'estore.field.footerLogoUrl': '頁尾 Logo',
  'estore.field.backorder': '開啟商品預購功能？',
  'estore.success.edit': '成功更新商家信息',
  'paymentConfig.title.list': '支付網關',
  'paymentConfig.title.add': '新增支付網關',
  'paymentConfig.title.delete': '刪除支付網關',
  'paymentConfig.message.delete': '確認刪除支付網關?',
  'paymentConfig.section.channel': '供應商',
  'paymentConfig.field.channel': '供應商',
  'paymentConfig.field.merchantId': '商戶ID',
  'paymentConfig.field.status': '狀態',
  'paymentConfig.field.key': 'Key',
  'paymentConfig.field.secret': 'Secret',
  'paymentConfig.field.ECPAY.key': 'Hash Key',
  'paymentConfig.field.ECPAY.secret': 'Hash IV',
  'paymentConfig.field.LINEPAY.key': 'Channel ID',
  'paymentConfig.field.LINEPAY.secret': 'Channel Secret',
  'paymentConfig.channel.CASH': '貨到付款',
  'paymentConfig.channel.ECPAY': '綠界',
  'paymentConfig.channel.LINEPAY': 'Line Pay',
  'paymentConfig.btn.toggleCash': '貨到付款',
  'discount.title.list': '活動管理',
  'discount.title.add': '新增活動設定',
  'discount.title.edit': '修改活動設定',
  'discount.title.delete': '刪除活動設定',
  'discount.title.generateCode': '自動生成折扣碼',
  'discount.title.addCode': '手動輸入折扣碼',
  'discount.message.delete': '確認刪除活動設定?',
  'discount.section.basic': '基礎信息',
  'discount.section.discount': '折扣信息',
  'discount.section.rule': '折扣規則',
  'discount.section.code': '折扣碼',
  'discount.field.seq': '序號',
  'discount.field.name': '活動名稱',
  'discount.field.code': '折扣編碼',
  'discount.field.startDate': '開始日期',
  'discount.field.endDate': '結束日期',
  'discount.field.discountType': '折扣類型',
  'discount.field.discountValue': '折扣數值',
  'discount.field.discountScope': '指定件數',
  'discount.field.product': '指定商品',
  'discount.field.quantity': '可使用次數',
  'discount.field.minQuantity': '最少購買數量',
  'discount.field.maxUserQuantity': '每人最多購買數量',
  'discount.field.minAmount': '最少購買金額',
  'discount.discountType.fixedAmount': '折抵金額',
  'discount.discountType.fixedPercentage': '折抵百分比',
  'discount.discountScope.all': '滿件數',
  'discount.discountScope.additional': '指定件數',
  'discount.field.codeCount': '生成數量',
  'discount.field.codeQuantity': '可使用次數',
  'discount.field.allowCombination': '可疊加使用',
  'discount.allowCombination.YES': '可',
  'discount.allowCombination.NO': '不可',
  'discount.btn.generateCode': '自動生成折扣碼',
  'discount.btn.addCode': '手動輸入折扣碼',
  'ad.title': '廣告設置',
  'ad.title.list': '首頁輪播',
  'ad.title.add': '新增輪播',
  'ad.title.edit': '更改輪播',
  'ad.title.delete': '刪除輪播',
  'ad.message.delete': '確認刪除輪播?',
  'ad.field.title': '標題',
  'ad.field.linkUrl': '連結',
  'ad.field.imageUrl': '圖片',
  'keyword.title.list': '首頁關鍵字',
  'keyword.title.add': '新增關鍵字',
  'keyword.title.edit': '更改關鍵字',
  'keyword.title.delete': '刪除關鍵字',
  'keyword.message.delete': '確認刪除關鍵字?',
  'keyword.field.title': '標題',
  'keyword.field.linkUrl': '連結',
  'adBlock.title.list': '首頁主打',
  'adBlock.title.add': '新增主打',
  'adBlock.title.edit': '更改主打',
  'adBlock.title.delete': '刪除主打',
  'adBlock.message.delete': '確認刪除主打?',
  'adBlock.field.title': '標題',
  'adBlock.field.linkUrl': '連結',
  'adBlock.field.imageUrl': '圖片',
  'categoryAd.title.list': '分類廣告',
  'categoryAd.title.add': '新增分類廣告',
  'categoryAd.title.delete': '刪除分類廣告',
  'categoryAd.message.delete': '確認刪除分類廣告?',
  'categoryAd.section.category': '商品分類',
  'productAd.title.list': '商品廣告',
  'productAd.section.basic': '基礎信息',
  'productAd.section.items': '商品列表',
  'productAd.title.add': '新增廣告商品',
  'productAd.title.edit': '更改廣告商品',
  'productAd.title.delete': '刪除廣告商品',
  'productAd.message.delete': '確認刪除廣告商品?',
  'productAd.btn.add': '新增商品',
  'productAd.field.name': '分類名稱',
  'productAd.field.merchantId': '商戶名稱',
  'productAd.field.productId': '商品名稱',
  'productAd.field.spu': '商品名稱',
  'support.title.list': '客服管理',
  'support.title.page': '客戶提問',
  'support.field.type': '類型',
  'support.field.subject': '標題',
  'support.field.message': '內容...',
  'support.field.reply': '回覆...',
  'support.field.status': '狀態',
  'support.field.createdAt': '日期',
  'support.type.BILLING': '支付',
  'support.type.PRODUCT': '商品',
  'support.type.ORDER': '訂單',
  'support.type.OTHER': '其他',
  'support.status.OPEN': '處理中',
  'support.status.CLOSED': '關閉',
  'support.status.RESOLVED': '已處理',
  'support.message.success': '成功更改提問狀態',
  'support.message.failed': '更改狀態失敗',
  'module.ad': '首頁輪播管理',
  'ad.add': '新增首頁輪播',
  'ad.edit': '修改首頁輪播',
  'ad.delete': '刪除首頁輪播',
  'ad.read': '讀取首頁輪播',
  'module.category': '商品分類管理',
  'category.add': '新增商品分類',
  'category.edit': '修改商品分類',
  'category.delete': '刪除商品分類',
  'module.customer': '客戶管理',
  'customer.add': '新增客戶',
  'customer.edit': '修改客戶',
  'customer.delete': '刪除客戶',
  'customer.read': '讀取客戶',
  'module.discount': '優惠券管理',
  'discount.add': '新增優惠券',
  'discount.edit': '修改優惠券',
  'discount.delete': '刪除優惠券',
  'module.inventory': '庫存管理',
  'inventory.read': '讀取庫存',
  'module.location': '門市管理',
  'location.add': '新增門市',
  'location.edit': '修改門市',
  'location.delete': '刪除門市',
  'module.merchant': '商戶管理',
  'merchant.add': '新增商戶',
  'merchant.edit': '修改商戶',
  'merchant.delete': '刪除商戶',
  'merchant.read': '讀取商戶',
  'module.payment': '支付管理',
  'payment.pay': '支付',
  'payment.add': '新增支付紀錄',
  'payment.refund': '退款',
  'payment.addConfig': '新增支付設定',
  'payment.editConfig': '修改支付設定',
  'payment.deleteConfig': '刪除支付設定',
  'payment.readConfig': '讀取支付設定',
  'module.permission': '權限管理',
  'permission.add': '新增權限',
  'permission.edit': '修改權限',
  'permission.delete': '刪除權限',
  'permission.read': '讀取權限',
  'module.product': '商品管理',
  'product.add': '新增商品',
  'product.edit': '修改商品',
  'product.delete': '刪除商品',
  'product.addTag': '新增商品標籤',
  'product.deleteTag': '刪除商品標籤',
  'product.addAd': '新增商品廣告',
  'product.editAd': '修改商品廣告',
  'product.deleteAd': '刪除商品廣告',
  'product.addShelf': '新增上架商品',
  'product.deleteShelf': '刪除上架商品',
  'module.staff': '員工管理',
  'staff.add': '新增員工',
  'staff.edit': '修改員工',
  'staff.delete': '刪除員工',
  'staff.read': '讀取員工',
  'module.support': '客服管理',
  'support.add': '新增客服訊息',
  'support.edit': '修改客服訊息',
  'support.read': '讀取客服訊息',
  'module.ticket': '訂單管理',
  'ticket.add': '新增訂單',
  'ticket.edit': '修改訂單',
  'ticket.confirm': '確認訂單',
  'ticket.cancel': '取消訂單',
  'ticket.read': '讀取訂單',
}
