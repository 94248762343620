import React, { useState, useContext, useEffect } from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import * as model from 'models/orderPage'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Link from 'components/Link'
import LinkButton from 'components/LinkButton'
import ConfirmButton from 'components/ConfirmButton'
import LoadingIcon from 'components/LoadingIcon'
import Image from 'components/Image'
import Message from 'components/Message'
import Table from 'components/Table'
import Tab from 'components/Tab'
import Page from 'components/PageContainer'
import OrderShipmentModal from 'views/OrderShipmentModal'
import OrderPaymentModal from 'views/OrderPaymentModal'
import OrderRefundModal from 'views/OrderRefundModal'
import { MdArrowBack } from 'react-icons/md'
import { formatDate } from 'utilities/dateUtil'
import Divider from 'components/Divider'
import Definition from 'components/Definition'
import DataPlaceholder from 'components/DataPlaceholder'

export default ({ match }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState({})
  const [paymentOpen, setPaymentOpen] = useState(false)
  const [paymentValue, setPaymentValue] = useState({})
  const [refundOpen, setRefundOpen] = useState(false)
  const [refundValue, setRefundValue] = useState({})
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const [refundToolbar, setRefundToolbar] = useState(false)
  const ticketId = match.params.id
  const {
    ticket,
    ticketItems,
    discountTransactions,
    payments,
    refTickets,
  } = state
  const shipping = ticket ? ticket.extra.shipping : {}
  const paymentMethod = ticket ? ticket.extra.paymentMethod : ''
  const receipt = ticket ? ticket.extra.receipt : ''
  const timeline = ticket ? ticket.extra.shipmentTimeline : []

  useEffect(() => {
    model.onLoad({ setState, app, session, ticketId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId])

  if (!ticket) {
    return <LoadingIcon />
  }

  const handlePaymentOpen = (item) => {
    setPaymentValue(item)
    setPaymentOpen(true)
  }

  const handleRefundClose = () => {
    setRefundOpen(false)
    setRefundValue({})
  }

  const handleRefundOpen = (item) => {
    setRefundValue(model.getRefundBalance(item, refTickets))
    setRefundOpen(true)
  }

  const handlePaymentClose = () => {
    setPaymentOpen(false)
    setPaymentValue({})
  }

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const handleRefresh = () => {
    model.onLoad({ setState, app, session, ticketId })
  }

  return (
    <Page
      title="orderDetail.title"
      navId="orderList"
      actions={
        <LinkButton variant="plain" url="/order">
          <MdArrowBack />
          <Message ml={1} id="btn.back" />
        </LinkButton>
      }
    >
      <Tab
        value={[
          {
            id: 'basic',
            label: <Message id="orderDetail.section.order" />,
            render: renderOrderTab({
              setState,
              app,
              session,
              ticket,
              discountTransactions,
              paymentMethod,
              receipt,
              shipping,
              ticketItems,
            }),
          },
          {
            id: 'payment',
            label: <Message id="orderDetail.section.payment" />,
            render: renderPaymentTab({ ticket, payments, handlePaymentOpen }),
          },
          {
            id: 'shipping',
            label: <Message id="orderDetail.section.shippingStatus" />,
            render: renderShipping({ ticket, timeline, handleOpen }),
          },
          {
            id: 'refund',
            label: <Message id="orderDetail.section.refund" />,
            render: renderRefundTab({
              ticket,
              ticketItems,
              refTickets,
              handleRefundOpen,
              setState,
              app,
              session,
              refundToolbar,
              setRefundToolbar,
            }),
          },
        ]}
      />
      <OrderPaymentModal
        open={paymentOpen}
        value={paymentValue}
        actions={{ handlePaymentClose, handleRefresh }}
      />
      <OrderRefundModal
        open={refundOpen}
        value={refundValue}
        actions={{ handleRefundClose, handleRefresh }}
      />
      <OrderShipmentModal
        open={open}
        value={value}
        actions={{ handleClose, handleRefresh }}
      />
    </Page>
  )
}

const renderOrderTab = ({
  setState,
  app,
  session,
  ticket,
  discountTransactions,
  paymentMethod,
  receipt,
  shipping,
  ticketItems,
}) => () => (
  <>
    <Flex justifyContent="space-between" alignItems="center" mb={3}>
      <StatusChip value={ticket.status} />
      <ConfirmButton
        disabled={model.disableCancel(ticket)}
        fontSize={2}
        title="orderDetail.title.cancel"
        message="orderDetail.message.cancel"
        variant="outlineAccent"
        onClick={(handleClose) => {
          model.cancelOrder({
            setState,
            app,
            session,
            ticketId: ticket.id,
            handleClose,
          })
        }}
      >
        <Message id="orderDetail.btn.cancelOrder" />
      </ConfirmButton>
    </Flex>
    <Box
      mt={2}
      mb={2}
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
        gridGap: 3,
      }}
    >
      <Box>
        <Definition label="orderDetail.field.ticketNo" value={ticket.id} />
        <Definition
          label="orderDetail.field.transDate"
          value={formatDate(new Date(ticket.transDate))}
        />
        <Definition
          label="orderDetail.field.orderPrice"
          value={`NT.${ticket.price}`}
        />
        <Definition
          label="orderDetail.field.shippingFee"
          value={`NT.${ticket.shippingFee}`}
        />
        {discountTransactions.length > 0 && (
          <Definition
            label="orderDetail.field.discountCode"
            value={discountTransactions.map(({ code }) => code).join(', ')}
          />
        )}
        {discountTransactions.length > 0 && (
          <Definition
            label="orderDetail.field.discountPrice"
            value={
              'NT.' +
              discountTransactions.reduce((result, item) => {
                result += item.discountValue
                return result
              }, 0)
            }
          />
        )}
      </Box>
      <Box>
        <Definition
          label="orderDetail.field.paymentMethod"
          value={getPaymentMethod(paymentMethod)}
        />
        <Definition
          label="receipt.field.receiptType"
          value={<Message id={`receipt.type.${receipt.type}`} />}
        />
        {receipt.receiptNo && (
          <Definition
            label="receipt.field.receiptNo"
            value={receipt.receiptNo}
          />
        )}
        {receipt.receiptTitle && (
          <Definition
            label="receipt.field.receiptTitle"
            value={receipt.receiptTitle}
          />
        )}
        {receipt.donationNo && (
          <Definition
            label="receipt.field.donationNo"
            value={receipt.donationNo}
          />
        )}
        {ticket.extra.paymentId && (
          <Definition
            label="order.field.paymentId"
            value={ticket.extra.paymentId}
          />
        )}
        {ticket.extra.transNo && (
          <Definition
            label="order.field.transNo"
            value={ticket.extra.transNo}
          />
        )}
      </Box>
      <Box>
        <Definition
          label="orderDetail.field.shipping.name"
          value={shipping.name}
        />
        <Definition
          label="orderDetail.field.shipping.phone"
          value={shipping.phone}
        />
        <Definition
          label="orderDetail.field.shipping.address"
          value={getShippingAddress(shipping)}
        />
        {shipping.memo && (
          <Definition
            label="orderDetail.field.shipping.memo"
            value={shipping.memo}
          />
        )}
      </Box>
    </Box>
    <Table
      width={1}
      columns={[
        {
          id: 'product',
          label: 'orderDetail.table.product',
          render: ({ row }) => (
            <Flex alignItems="center">
              <Image
                src={row.image.src}
                width="64px"
                height="64px"
                sx={{ objectFit: 'contain' }}
              />
              <Box mx={3}>
                <Text>{row.spu}</Text>
                {row.options && row.options.length > 0 && (
                  <span>{row.options.map(({ value }) => value).join(' ')}</span>
                )}
                <Text fontSize={0} color="grey.4">
                  {row.sku}
                </Text>
              </Box>
            </Flex>
          ),
        },
        {
          id: 'unitPrice',
          label: 'orderDetail.table.unitPrice',
          align: 'right',
          render: ({ row }) => <Text>{row.price}</Text>,
        },
        {
          id: 'quantity',
          label: 'orderDetail.table.quantity',
          align: 'right',
        },
        {
          id: 'price',
          label: 'orderDetail.table.price',
          align: 'right',
          render: ({ row }) => {
            const { price, quantity } = row
            return <Text>{price * quantity}</Text>
          },
        },
      ]}
      rows={ticketItems}
    />
  </>
)

function StatusChip({ value }) {
  return (
    <Box
      display="inline-flex"
      py={1}
      px={2}
      bg="grey.2"
      color="grey.5"
      fontSize={1}
      sx={{
        alignItems: 'center',
        borderRadius: '15px',
      }}
    >
      <Box
        display="inline-block"
        height="0.8em"
        width="0.8em"
        mr={2}
        bg={getStatusColor(value)}
        sx={{
          borderRadius: '50%',
        }}
      />
      <Message id={`order.status.${value}`} />
    </Box>
  )
}

function getStatusColor(status) {
  switch (status) {
    case 'ACTIVE':
    case 'FULL_REFUND':
    case 'PARTIAL_REFUND':
      return 'success.1'
    case 'SHIP_PENDING':
    case 'REFUND_PENDING':
      return 'warning.1'
    default:
      return 'grey.4'
  }
}

const renderPaymentTab = ({ ticket, payments, handlePaymentOpen }) => () => (
  <>
    <Flex justifyContent="flex-end" alignItems="center" mb={3}>
      <Button
        disabled={ticket.status !== 'PAY_PENDING'}
        type="button"
        variant="outlineSecondary"
        onClick={() => handlePaymentOpen({ id: ticket.id })}
      >
        <Message id="orderDetail.btn.addPayment" />
      </Button>
    </Flex>
    <Table
      width={1}
      columns={[
        {
          id: 'transType',
          label: 'payment.field.transType',
          render: ({ row }) => <Text>{getTransType(row.transType)}</Text>,
        },
        {
          id: 'channelType',
          label: 'payment.field.channelType',
          render: ({ row }) => <Text>{getChannelType(row.channelType)}</Text>,
        },
        {
          id: 'transDate',
          label: 'payment.field.transDate',
          render: ({ row }) => (
            <Text>{formatDate(new Date(row.transDate))}</Text>
          ),
        },
        {
          id: 'channelProvider',
          label: 'payment.field.channelProvider',
          render: ({ row }) => (
            <Text>{getChannelProvider(row.channelProvider)}</Text>
          ),
        },
        {
          id: 'status',
          label: 'payment.field.status',
          render: ({ row }) => <Message id={`payment.status.${row.status}`} />,
        },
        {
          id: 'transAmount',
          label: 'payment.field.transAmount',
          align: 'right',
        },
      ]}
      rows={payments}
    />
  </>
)

const renderShipping = ({ ticket, timeline, handleOpen }) => () => (
  <>
    <Flex justifyContent="flex-end" alignItems="center" mb={3}>
      <Button
        disabled={!['SHIP_PENDING', 'ACTIVE'].includes(ticket.status)}
        type="button"
        variant="outlineSecondary"
        onClick={() => handleOpen({ id: ticket.id })}
      >
        <Message id="orderDetail.btn.addShippingStatus" />
      </Button>
    </Flex>
    <Table
      width={1}
      columns={[
        {
          id: 'timestamp',
          label: 'shipping.field.timestamp',
        },
        {
          id: 'status',
          label: 'shipping.field.status',
          render: ({ row }) => <Message id={`order.status.${row.status}`} />,
        },
        {
          id: 'memo',
          label: 'shipping.field.memo',
        },
      ]}
      rows={timeline}
    />
  </>
)

const renderRefundTab = ({
  ticket,
  ticketItems,
  refTickets,
  handleRefundOpen,
  setState,
  app,
  session,
  refundToolbar,
  setRefundToolbar,
}) => () => (
  <>
    <Flex justifyContent="flex-end" alignItems="center" mb={3}>
      <Button
        disabled={ticket.status === 'INACTIVE'}
        type="button"
        variant="outlineSecondary"
        onClick={() => handleRefundOpen({ id: ticket.id, ticketItems })}
      >
        <Message id="orderDetail.btn.addRefund" />
      </Button>
    </Flex>
    {refTickets.length > 0 ? (
      refTickets.map((item, index) => (
        <Box key={item.id} mb={4}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gridColumnGap: 3,
            }}
          >
            <Definition label="refund.field.id" value={item.id} />
            <Definition
              label="refund.field.transDate"
              value={formatDate(new Date(item.transDate))}
            />
            <Flex mb={3} lineHeight={1.5}>
              <Message flex={1.2} id="refund.field.status" color="grey.4" />
              {item.status === 'PENDING' ? (
                <Link
                  variant="link"
                  flex={3}
                  onMouseOver={() => setRefundToolbar(true)}
                  onMouseOut={() => setRefundToolbar(false)}
                >
                  <Message mr="auto" id={`refund.status.${item.status}`} />
                  <Box display={refundToolbar ? 'flex' : 'none'}>
                    <ConfirmButton
                      py={0}
                      px={2}
                      fontSize={2}
                      title="order.title.cancelRefund"
                      message="order.message.cancelRefund"
                      variant="plain"
                      onClick={(handleClose) => {
                        model.cancelRefund({
                          setState,
                          app,
                          session,
                          ticketId: ticket.id,
                          refundId: item.id,
                          handleClose,
                        })
                      }}
                    >
                      <Message id="btn.reject" />
                    </ConfirmButton>
                    <ConfirmButton
                      py={0}
                      px={2}
                      fontSize={2}
                      title="order.title.confirmRefund"
                      message="order.message.confirmRefund"
                      variant="plain"
                      onClick={(handleClose) => {
                        model.confirmRefund({
                          setState,
                          app,
                          session,
                          ticketId: ticket.id,
                          refundId: item.id,
                          handleClose,
                        })
                      }}
                    >
                      <Message id="btn.approve" />
                    </ConfirmButton>
                  </Box>
                </Link>
              ) : (
                <Message flex={3} id={`refund.status.${item.status}`} />
              )}
            </Flex>
          </Box>
          <Table
            width={1}
            columns={[
              {
                id: 'product',
                label: 'orderDetail.table.product',
                render: ({ row }) => {
                  const product = ticketItems.find(
                    (item) =>
                      item.productVariantId === `${row.productVariantId}`
                  )
                  return (
                    <Flex alignItems="center">
                      <Image
                        src={product.image.src}
                        width="64px"
                        height="64px"
                        sx={{ objectFit: 'contain' }}
                      />
                      <Text mx={3}>{product.spu}</Text>
                    </Flex>
                  )
                },
              },
              {
                id: 'quantity',
                label: 'refund.field.quantity',
                align: 'right',
              },
            ]}
            rows={item.ticketItems}
          />
          {index < refTickets.length - 1 && <Divider mt={4} bg="grey.3" />}
        </Box>
      ))
    ) : (
      <DataPlaceholder />
    )}
  </>
)

function getShippingAddress(shipping) {
  let address = ''
  if (shipping.zipcode) address += shipping.zipcode + ' '
  if (shipping.city) address += shipping.city
  if (shipping.district) address += shipping.district
  if (shipping.address) address += shipping.address
  return address
}

function getTransType(status) {
  switch (status) {
    case 'PAYMENT':
      return <Message id="payment.transType.payment" />
    case 'REFUND':
      return <Message id="payment.transType.refund" />
    default:
      return status
  }
}

function getChannelType(status) {
  switch (status) {
    case 'CASH':
      return <Message id="payment.channelType.cash" />
    case 'CREDIT_CARD':
      return <Message id="payment.channelType.creditCard" />
    default:
      return status
  }
}

function getChannelProvider(status) {
  switch (status) {
    case 'SELF':
      return <Message id="payment.channelProvider.self" />
    case 'ECPAY':
      return <Message id="payment.channelProvider.ecpay" />
    case 'LINEPAY':
      return <Message id="payment.channelProvider.linepay" />
    default:
      return status
  }
}

function getPaymentMethod(value) {
  switch (value) {
    case 'CASH':
      return <Message id="payment.method.cash" />
    default:
      return value
  }
}
