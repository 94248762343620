import React from 'react'
import { Box, Flex, Text } from 'rebass'
import Message from 'components/Message'
import Link from 'components/Link'
import TextInput from 'components/TextInput'
import Select from 'components/Select'
import { request } from 'utilities/requestUtil'
import { formatDate } from 'utilities/dateUtil'

export const columns = ({ message }) => [
  {
    id: 'id',
    label: 'order.field.id',
    render: ({ row }) => (
      <Link url={`/order/${row.id}`}>
        <Text variant="link">{row.id}</Text>
      </Link>
    ),
  },
  {
    id: 'toLocation',
    label: 'order.field.toLocation',
  },
  {
    id: 'transDate',
    label: 'order.field.transDate',
    render: ({ row }) => <Text>{formatDate(new Date(row.transDate))}</Text>,
  },
  {
    id: 'status',
    label: 'order.field.status',
    render: ({ row }) => <StatusChip value={row.status} />,
    renderText: ({ row }) => message({ id: `order.status.${row.status}` }),
  },
]

function StatusChip({ value }) {
  return (
    <Box
      display="inline-flex"
      py={1}
      px={2}
      bg="grey.2"
      color="grey.5"
      fontSize={1}
      sx={{
        alignItems: 'center',
        borderRadius: '15px',
      }}
    >
      <Box
        display="inline-block"
        height="0.8em"
        width="0.8em"
        mr={2}
        bg={getStatusColor(value)}
        sx={{
          borderRadius: '50%',
        }}
      />
      <Message id={`order.status.${value}`} />
    </Box>
  )
}

function getStatusColor(status) {
  switch (status) {
    case 'ACTIVE':
    case 'FULL_REFUND':
    case 'PARTIAL_REFUND':
      return 'success.1'
    case 'SHIP_PENDING':
    case 'REFUND_PENDING':
      return 'warning.1'
    default:
      return 'grey.4'
  }
}

export const filters = ({
  message,
  filterOp,
  setFilterOp,
  filterInput,
  setFilterInput,
}) => [
  {
    id: 'ticketId',
    label: 'order.field.id',
    input: (
      <TextInput
        containerProps={{ mb: [2, 0], flex: [1, 3], width: 1 }}
        value={filterInput}
        onChange={({ target }) => setFilterInput(target.value)}
      />
    ),
  },
  {
    id: 'transDate',
    label: 'order.field.transDate',
    op: (
      <Select
        containerProps={{ mb: [2, 0], mr: 3, flex: 1, width: 1 }}
        isSearchable={false}
        isClearable={false}
        placeholder="filter.placeholder"
        options={[
          {
            value: 'EQ',
            label: message({ id: 'filter.op.eq' }),
          },
          {
            value: 'GE',
            label: message({ id: 'filter.op.ge' }),
          },
          {
            value: 'LE',
            label: message({ id: 'filter.op.le' }),
          },
        ]}
        value={filterOp}
        onChange={setFilterOp}
      />
    ),
    input: (
      <TextInput
        containerProps={{ mb: [2, 0], flex: [1, 2], width: 1 }}
        type="date"
        value={filterInput}
        onChange={({ target }) => setFilterInput(target.value)}
      />
    ),
    getLabel: (item) => (
      <Flex alignItems="center" sx={{ lineHeight: 0 }}>
        {message({ id: 'order.field.transDate' })}
        <Text px={1}>
          {message({ id: `filter.op.${item.op.toLowerCase()}` })}
        </Text>
        {item.value}
      </Flex>
    ),
  },
  // {
  //   id: 'status',
  //   label: 'order.field.status',
  //   input: (
  //     <Select
  //       containerProps={{ mb: [2, 0], mr: 3, flex: 1, width: 1 }}
  //       isSearchable={false}
  //       isClearable={false}
  //       placeholder="filter.placeholder"
  //       options={[
  //         {
  //           value: 'INACTIVE',
  //           label: message({ id: 'order.status.INACTIVE' }),
  //         },
  //         {
  //           value: 'PENDING',
  //           label: message({ id: 'order.status.PENDING' }),
  //         },
  //         {
  //           value: 'ACTIVE',
  //           label: message({ id: 'order.status.ACTIVE' }),
  //         },
  //         {
  //           value: 'SHIP_PENDING',
  //           label: message({ id: 'order.status.SHIP_PENDING' }),
  //         },
  //         {
  //           value: 'PAY_PENDING',
  //           label: message({ id: 'order.status.PAY_PENDING' }),
  //         },
  //         {
  //           value: 'FULL_REFUND',
  //           label: message({ id: 'order.status.FULL_REFUND' }),
  //         },
  //         {
  //           value: 'PARTIAL_REFUND',
  //           label: message({ id: 'order.status.PARTIAL_REFUND' }),
  //         },
  //       ]}
  //       value={filterInput}
  //       onChange={setFilterInput}
  //     />
  //   ),
  //   getLabel: ({ value }) => (
  //     <Flex alignItems="center" sx={{ lineHeight: 0 }}>
  //       {message({ id: 'order.field.status' })}
  //       <Text px={1}>{message({ id: 'filter.op.eq' })}</Text>
  //       {message({ id: `order.status.${value.value}` })}
  //     </Flex>
  //   ),
  // },
]

export const onLoad = async ({
  setState,
  setExcel,
  session,
  app,
  page,
  filter = [],
  values,
}) => {
  const { merchantId } = app.state.staff
  const countPerPage = setExcel ? -1 : 25
  const { hotFilter } = values
  const input = {
    ticketType: 'SELL',
    page,
    countPerPage,
  }

  if (hotFilter !== 'ALL') {
    input.status = hotFilter
  }

  filter.forEach((item) => {
    let key = item.id
    switch (item.id) {
      case 'ticketId':
        input.id = item.value
        break
      case 'transDate':
        switch (item.op) {
          case 'GE':
            input.fromTransDate = item.value
            break
          case 'LE':
            input.toTransDate = item.value
            break
          default:
            input.fromTransDate = item.value
            input.toTransDate = item.value
        }
        break
      // case 'status':
      //   input[key] = item.value.value
      //   break
      default:
        input[key] = item.value
    }
  })

  const variables = { merchantId, input }
  const query = `
    query($merchantId: ID, $input: TicketQueryInput) {
      tickets(merchantId: $merchantId, input: $input) {
        data {
          id
          refId
          toLocation
          transDate
          amountBilled
          amountAcquired
          status
        }
        pagination {
          totalCount
          totalPage
          page
          pages
          prevPage
          nextPage
          countPerPage
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  if (setExcel) {
    setExcel(data.tickets.data)
    return
  }

  setState(data.tickets)
}

export const exportColumns = ({ message }) => [
  {
    id: 'ticketId',
    label: 'order.field.id',
  },
  {
    id: 'transDate',
    label: 'order.field.transDate',
    renderText: ({ row }) => formatDate(new Date(row.transDate)),
  },
  {
    id: 'price',
    label: 'orderDetail.field.orderPrice',
  },
  {
    id: 'shippingFee',
    label: 'orderDetail.field.shippingFee',
  },
  {
    id: 'discountCodes',
    label: 'orderDetail.field.discountCode',
  },
  {
    id: 'discountAmount',
    label: 'orderDetail.field.discountPrice',
  },
  {
    id: 'paymentMethod',
    label: 'orderDetail.field.paymentMethod',
  },
  {
    id: 'transNo',
    label: 'orderDetail.field.transNo',
  },
  {
    id: 'status',
    label: 'order.field.status',
    renderText: ({ row }) => message({ id: `order.status.${row.status}` }),
  },
  {
    id: 'spu',
    label: 'orderDetail.table.product',
    renderText: ({ row }) => getProductName(row.spu, row.options),
  },
  {
    id: 'sku',
    label: 'product.field.sku',
  },
  {
    id: 'price',
    label: 'orderDetail.table.unitPrice',
  },
  {
    id: 'quantity',
    label: 'orderDetail.table.quantity',
  },
  {
    id: 'shippingName',
    label: 'orderDetail.table.shippingName',
    renderText: ({ row }) => JSON.parse(row.shipping).name,
  },
  {
    id: 'shippingPhone',
    label: 'orderDetail.table.shippingPhone',
    renderText: ({ row }) => JSON.parse(row.shipping).phone,
  },
  {
    id: 'shippingAddress',
    label: 'orderDetail.table.shippingAddress',
    renderText: ({ row }) => getShippingAddress(JSON.parse(row.shipping)),
  },
]

export const onExport = async ({ setExcel, session, app, filter = [] }) => {
  const input = {}
  filterInput(input, filter)
  const variables = { input }
  const query = `
    query($input: SellReportInput) {
      sellReport(input: $input) {
        ticketId
        transNo
        transDate
        transAmount
        shippingFee
        discountCodes
        discountAmount
        paymentMethod
        shipping
        amountBilled,
        amountAcquired,
        status
        spu
        sku
        options
        price
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  setExcel(data.sellReport)
}

function filterInput(input, filter) {
  filter.forEach((item) => {
    let key = item.id
    switch (item.id) {
      case 'ticketId':
        input.id = item.value
        break
      case 'transDate':
        switch (item.op) {
          case 'GE':
            input.fromTransDate = item.value
            break
          case 'LE':
            input.toTransDate = item.value
            break
          default:
            input.fromTransDate = item.value
          // input.toTransDate = item.value
        }
        break
      default:
        input[key] = item.value
    }
  })
}

function getProductName(spu, options) {
  if (!options) {
    return spu
  }
  const optionVal = options.map((item) => item.value).join(', ')
  return `${spu} [${optionVal}]`
}

function getShippingAddress(shipping) {
  let address = ''
  if (shipping.zipcode) address += shipping.zipcode + ' '
  if (shipping.city) address += shipping.city
  if (shipping.district) address += shipping.district
  if (shipping.address) address += shipping.address
  return address
}
